.App {
  text-align: center;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: #1D1D1D;
  width: 100%;
}

.bold-text {
  font-weight: 600;
  font-size: 3rem; /* Justera denna värde för att ändra storleken på texten */
  font-family: "FT System Blank", Arial, Helvetica, sans-serif;
}

.welcome-text {
  font-size: 1rem;
}

.footer-container {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  padding-bottom: 20px;
}

.container {
  max-width: 1200px;
  width: 100%;
}

/* Initial position */
.header-animate-start {
  padding-top: 15vh;
  transition: padding-top 0.5s;
}

/* Position efter sökning */
.header-animate-end {
  padding-top: 2vh;
  transition: padding-top 0.5s;
}

.card-img-container {
  width: 210px;
  height: 180px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.card-img-custom {
  max-height: 150px;
  max-width: 210px;
  height: auto;
  object-fit: contain;
}

.card-text-left {
  text-align: left;
}

.search-input {
  max-width: 800px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 20px; /* Lägger till 20px distans under sökfältet */
}

.results {
  width: 1200px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Centrerar objekten inuti .results */
}

.counter-box h1 {
  font-size: 3rem; /* eller valfri storlek */
}

.counter-box h4 {
  font-size: 1rem;
}

.logo {
  height: 150px; /* Ersätt [DinLoggasHöjd] med din faktiska loggahöjd */
  padding-bottom: 30px;
  transition: opacity 0.5s, height 0.5s; /* Lägg till övergången för höjd här */
  margin-bottom: 50px; /* Lägg till detta för att skapa 50px avstånd */
  margin-top: -50px; 
}

.logo.hide-logo {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 0.2s, height 0.5s;
}

/* Alternativt kan du skapa en ny klass för mellanrummet */
.logo-text-spacing {
  margin-bottom: 50px;
}
