
  .disabled-field {
    color: #a0a0a0;
  }

/* styles/ClimateModal.css */


  .clickable-value:hover {
    color: #006699; /* Change color on hover */
  }
  
  .alert-wrapper {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
}

.clickable-value.icon-clicked {
  color: rgb(244, 14, 14); /* Eller vilken färg du föredrar för texten */
}

.bi-clipboard {
  padding-left: 5px; /* Ändra detta värde beroende på hur mycket mellanrum du vill ha */
}




.download-text {
  display: none; /* Göm texten som standard */
}

.large-icon {
  font-size: 1.8rem;
  vertical-align: middle;
  margin-right: 5px;
}

.custom-button {
  min-width: 200px;  /* eller en annan lämplig bredd */
  margin-left: 120px; /* alternativt kan du använda Bootstrap's ml-auto klass */
}

.right-align {
  margin-left: auto !important;
}


