.custom-navbar {
  background-color: #0B427A;
  box-shadow: 0px 3px 10px rgba(0,0,0,0.2); /* X-offset Y-offset blur spread color */
}
.custom-navbar .navbar-collapse {
  justify-content: flex-end;
  padding-right: 20px;  
}

/* Ny stil för knappen */
.custom-navbar .btn-outline-light {
  background-color: #ffffff;
  color: #0B427A;
  clip-path: polygon(
    6px 0,
    calc(100% - 6px) 0,
    100% 6px,
    100% calc(100% - 6px),
    calc(100% - 6px) 100%,
    6px 100%,
    0 calc(100% - 6px),
    0 6px
  );
  transition: background-color 0.3s, color 0.3s;
}

/* Hover-effekt för knappen */
.custom-navbar .btn-outline-light:hover {
  background-color: #ffffff;
  color: #0B427A;
}
