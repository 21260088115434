.card-img-container {
  width: 210px;
  height: 180px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.card-img-custom {
  max-height: 150px;
  max-width: 210px;
  height: auto;
  object-fit: contain;
}

.card-text-left {
  text-align: left;
}

.search-input {
  max-width: 800px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: #F4F4F4; /* Uppdaterad bakgrundsfärg */
}

.search-input input {
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  background-color: #F4F4F4; /* Uppdaterad bakgrundsfärg */
  font-family: 'FT System Mono', 'Courier New', monospace !important;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}

.search-input input:focus {
  background-color: #F4F4F4; /* Behåll samma färg vid fokus */
}

/* Stilar för placeholder-text */
.search-input input::placeholder {
  font-family: 'FT System Mono', 'Courier New', monospace !important;
  font-weight: 500;
  font-size: 16px;
  color: #4A4A4A; /* Mörkare färg för bättre läsbarhet mot den ljusa bakgrunden */
}

/* För att säkerställa kompatibilitet med olika webbläsare */
.search-input input::-webkit-input-placeholder,
.search-input input::-moz-placeholder,
.search-input input:-ms-input-placeholder,
.search-input input:-moz-placeholder {
  font-family: 'FT System Mono', 'Courier New', monospace !important;
  font-weight: 500;
  color: #4A4A4A;
}

.results {
  width: 1200px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Centrerar objekten inuti .results */
}

.no-results-text {
  font-size: 1rem;
}

.info-text-container {
  margin-top: 20px;
  padding: 20px; /* Ökad padding */
  font-size: 1rem;
  line-height: 1.6;
  width: 100;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  margin-bottom: 20px;

  background-color: #ffffff; /* Exempel på grå bakgrundsfärg */

  border-radius: 10px; /* Lägg till rundade hörn om du vill */
}

.info-text-container h3 {
  margin-top: 15px;
  font-size: 1.2rem;
}

.info-text-container ul {
  list-style-type: disc;
  padding-left: 20px;
}

.info-text-container p {
  margin-bottom: 10px;
}

.aa-DetachedSearchButton {
  display: none !important;
}

/* Om sökfältet har en inre input, kan du behöva applicera clip-path på den också */
.search-input input {
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  background-color: #f0f0f0; /* Justera färgen efter behov */
}

/* Andra stilar ... */
