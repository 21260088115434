.cookie-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* halvgenomskinlig svart överlagring */
    z-index: 9999; /* För att säkerställa att överlagringen ligger över allt annat innehåll */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.cookie-alert {
    margin-bottom: 0;
    width: 100%;
    height: 20%;
    min-height: 200px;
    border-radius: 0; 
    border: 0px;
    display: flex;
    justify-content: center;  /* Centrerar innehållet horisontellt */
    align-items: center;      /* Centrerar innehållet vertikalt */
    flex-direction: column;  
}

.cookie-alert .btn {
    margin-top: 15px;
    padding: 12px 24px; /* Ökar knappens vaddering */
    font-size: 18px; /* Gör texten inuti knappen större */
}

.cookie-text-container {
    max-width: 1000px; /* eller vilken maxbredd du föredrar */
    margin-left: auto;
    margin-right: auto;
}
