.counter-box {
    display: inline-block;
    margin: 10px;
    padding: 20px;
    background-color: #D7D3CE;
    text-align: center;
    border: 1px solid #D7D3CE;
    color: #434343; /* Ändra textfärgen till grå */
    width: 370px; /* Fixerad bredd */
    height: 150px; /* Fixerad höjd */
    box-sizing: border-box; /* Inkludera padding i höjd och bredd */
    clip-path: polygon(
        10px 0,
        calc(100% - 10px) 0,
        100% 10px,
        100% calc(100% - 10px),
        calc(100% - 10px) 100%,
        10px 100%,
        0 calc(100% - 10px),
        0 10px
    );
}

.counter-value {
    font-size: 3em;
    font-family: 'FT System Mono', monospace;
    font-weight: 500;
}

.counter-label {
    font-size: 0.8em;
}
