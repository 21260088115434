.footerStyle {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    background-color: #D7D3CE;
    padding: 20px;
    text-align: left;
    color: #1D1D1D;
    clip-path: polygon(
        10px 0,
        calc(100% - 10px) 0,
        100% 10px,
        100% calc(100% - 10px),
        calc(100% - 10px) 100%,
        10px 100%,
        0 calc(100% - 10px),
        0 10px
    );
}

.firstColStyle {
    width: 50%;
}

.otherColStyle {
    flex: 1; /* Låter kolumnerna växa i förhållande till sitt innehåll */
    min-width: 0; /* Undvik att kolumnen går utanför container */
    padding-right: 20px; /* Lägger till lite utrymme till höger */
    color: #1D1D1D;
}

.otherColStyle:last-child {
    padding-right: 0; /* Tar bort extra padding för sista kolumnen */
}

.link-style {
    color: inherit;        /* Använd förälderns textfärg */
    text-decoration: none; /* Ta bort understrykning */
}

.link-style:hover {
    text-decoration: underline; /* Lägg till understrykning vid hover */
}
